<template>
  <div class="search-layout">
    <transition name="fade">
      <div
        class="filter-bar-backdrop"
        v-show="showAdvanced"
        @click="toggleAdvanced"
      ></div>
    </transition>
    <transition name="grow" @after-enter="onTransition" @after-leave="onTransition">
      <div v-show="showAdvanced" class="filter-bar bg-dark text-light" :style="{top:  navHeight, height: `calc(100vh - ${navHeight})`}">
        <div class="filter-bar-content">
          <div class="filter-bar-header">
            <h6 class="filter-bar-title">Filters</h6>
            <button
              type="button"
              class="btn btn-outline-dark ml-1"
              v-show="filtersActive"
              @click="resetFilters"
            >
              Clear All
            </button>
            <button
              type="button"
              class="btn btn-outline-dark ml-1"
              aria-label="Close"
              @click="toggleAdvanced()"
            >
              <IconX class="icon"/>
            </button>
          </div>
          <SearchFilters :facets="facets" :value="$route.query" @input="onFilter" />
        </div>
      </div>
    </transition>
    <div class="search-container mb-chat container-fluid">
      <div class="search-head py-2"
        :class="{'show-advanced':showAdvanced}"
      >
        <div class="search-head-wing">
          <DisclosureTriangle
            @click="toggleAdvanced()"
            :active="showAdvanced"
            class="rotate-180"
          >
            Filters
            <span
              class="badge badge-pill badge-primary"
              v-show="filtersActive"
            >
              {{filtersActive}}
            </span>
          </DisclosureTriangle>
        </div>
        <PaginationNav
          class="search-head-wing justify-content-end"
          :total="total"
          :limit="limit"
          :compact="true"
        ></PaginationNav>
      </div>

      <div class="d-flex flex-wrap align-items-baseline mx-1">
        <h4 v-if="q_" class="mb-0 mr-2">{{q_}}</h4>

        <SearchTips button-class="text-secondary p-0 border-0 mr-3 mt-n2"/>
        <div class="text-secondary">{{response.total_is_partial ? 'At least ' : ''}}{{response.total | formatCount}}</div>
        <div class="text-secondary" v-if="loading && response.total === undefined">Loading...</div>
        <SortMenu class="ml-auto text-right" :sort="sort"/>
      </div>

      <b-alert
        v-for="error in response.errors"
        :key="errorsText(error)"
        show
        variant="warning"
        class="mt-2"
      >{{errorsText(error)}}</b-alert>

      <RelatedKeywords :items="mock.related"></RelatedKeywords>
      <MediaGrid
        v-if="groups"
        :groups="groups"
        :lazy="false"
        :id.sync="selectedId"
        @replaceState="onReplaceState"
        ref="primaryGrid"
      />
      <Pagination
        class="pagination-row mx-1"
        v-show="resultsCount"
        :total="total"
        :limit="limit"
      >
        <div>{{response.total | formatCount}}</div>
      </Pagination>
      <div v-if="$route.query.api !== undefined" class="alert alert-info my-3 text-center">
        {{$route.query.api === 'internal' ? 'Results from our extended catalog are hidden.' : 'Some results are hidden.'}}
        <SearchShowAllApis/>
      </div>
    </div>
  </div>
</template>

<script>
import { debounce, groupBy, startCase } from 'lodash'

import DisclosureTriangle from '@/components/DisclosureTriangle.vue'
import Pagination from '@/components/Pagination.vue'
import PaginationNav from '@/components/PaginationNav.vue'
import MediaGrid from '@/components/MediaGrid.vue'
import SearchFilters from '@/components/SearchFilters.vue'
import RelatedKeywords from '@/components/RelatedKeywords.vue'
import SearchShowAllApis from '@/components/SearchShowAllApis.vue'
import SearchTips from '@/components/SearchTips.vue'
import SortMenu from '@/components/SortMenu.vue'
import { getWorkspaces } from '@/components/QuickAddWorkspace.vue'
import { singleAdapter, splitAdapter } from '@/services/searchAdapters'
import { SEARCH_LIMIT_DEFAULT } from '@/services/constants.js'
import { mediaBreakpointUp } from '@/utils/breakpoints.js'
import { datetime } from '@/utils/datetime.js'

import IconX from '@primer/octicons/build/svg/x-16.svg'

export default {
  name: 'Search',
  components: {
    DisclosureTriangle,
    Pagination,
    PaginationNav,
    MediaGrid,
    SearchFilters,
    RelatedKeywords,
    SearchShowAllApis,
    SearchTips,
    SortMenu,
    IconX,
  },
  props: {
    q: String,
    limit: {
      default: SEARCH_LIMIT_DEFAULT,
    },
    sort: String,
    api: String,
    group: String,
  },
  data() {
    return {
      loading: false,
      response: {},
      mock: {},
      // show sidebar if pref is true and screen is large enogh to show it
      showAdvanced: this.$store.state.preferences.searchAdvanced && mediaBreakpointUp('sm'),
      facets: {},
      q_: this.q,
    }
  },
  computed: {
    navHeight() {
      return this.$store.state.appearance.navHeight
    },
    groups() {
      const results = this.response && this.response.images
      if (!results) { return }
      return (this.group)
        ? groupBy(results, i => datetime(i.created, this.group) || 'No date')
        : { results }
    },
    resultsCount() {
      return this.response.images && this.response.images.length
    },
    filtersActive() {
      const exclude = ['q', 'offset', 'limit', 'sort', 'code', '_ga']
      return Object.entries(this.$route.query)
        // filter out params in blacklist and params with an undefined value
        .filter(([k, v]) => !exclude.includes(k) && v !== undefined)
        .length
    },
    adapter() {
      return (this.api !== undefined) ? singleAdapter : splitAdapter
    },
    total() {
      const { total, total_available, total_for_pagination } = this.response
      return total_for_pagination || total_available || total || 0
    },
    selectedId: {
      get() {
        if (!this.$route.hash) { return }
        return this.$route.hash.replace(/^#/, '')
      },
      set(id) {
        const hash = id ? `#${id}` : undefined
        this.$router.push({...this.$route, hash})
      },
    },
  },
  watch: {
    '$route.query': {
        handler(newVal, oldVal) {
          // update results when query changes
          if (JSON.stringify(newVal) === JSON.stringify(oldVal)) { return }
          this.debouncedOnPropChange()
        },
        deep: true,
    },
    '$store.state.preferences.searchRefresh'() {
      this.debouncedOnPropChange()
    },
    selectedId(id) {
      // set document title
      const results = this.response && this.response.images
      const item = results && results.find(i => i.id === id)
      const title = (item && item.title) || id
      this.$nextTick(() => {
        const q = this.$route.query.q
        document.title = (q ? `${q} - ` : '') + (title ? `"${title}"` : 'Great American Art Search')
      })
    },
  },
  created() {
    this.debouncedOnPropChange = debounce(this.onPropChange, 0, {leading: true, trailing: false})
    this.onPropChange()
    // preload workspaces cache
    getWorkspaces()
  },
  mounted() {
    window.addEventListener('keyup', this.onKeyboardNav);
  },
  destroyed() {
    window.removeEventListener('keyup', this.onKeyboardNav);
  },
  methods: {
    onPropChange() {
      const baseParams = {
      }
      this.getResults(Object.assign({}, baseParams, this.$route.query))
    },
    async getResults(params) {
      this.loading = true
      this.$SmartProgress.start()
      try {
        this.response = await this.adapter.get(params)
        this.q_ = this.q
        this.facets = this.response.facets
        // const mock = await http.get('http://localhost:8080/mocks/cats.json')
        // this.mock = mock.data
        this.$SmartProgress.finish()
      } catch (err) {
        this.response = {}
        this.$SmartProgress.fail()
        this.$alert.persistent(`${err}\nPlease try again. Contact customer support if this persists.`, {variant: 'danger'})
      } finally {
        this.loading = false
      }
    },
    onFilter(val) {
      const {offset, ...query} = val // omit offset when changing filters
      this.$router.push({query})
    },
    onReplaceState(id) {
      const hash = id ? `#${id}` : undefined
      this.$router.replace({...this.$route, hash})
    },
    onTransition() {
      if (mediaBreakpointUp('sm')) {
        // tell MediaGrid about resize when sidebar affects layout
        this.$refs.primaryGrid?.onResize(null, {force: true})
      }
    },
    onKeyboardNav(event) {
      switch (event.key) {
        case 'ArrowLeft':
          this.$refs.primaryGrid.onLeft(event)
          break
        case 'ArrowRight':
          this.$refs.primaryGrid.onRight(event)
          break
        case 'Escape':
          this.$refs.primaryGrid.onEscape(event)
          break
      }
    },
    toggleAdvanced() {
      this.showAdvanced = !this.showAdvanced
      this.onTransition()
      if (mediaBreakpointUp('sm')) {
        // only set pref when sidebar is affecting layout, toggling on mobile does not show intent
        this.$store.commit('preferences/setPreference', {searchAdvanced: this.showAdvanced})
      }
    },
    resetFilters() {
      // reset filters by eliminating every query param except 'q'
      // empty string fallback is important, because if 'q' were undefined,
      // it would be filtered out by vue-router, and url would not update
      this.$router.push({query: {q: this.$route.query.q || ''}})
    },
    errorsText(i) {
      return `${startCase(i?.config?.params?.api)} results not available. ${i}`
    },
  },
}
</script>

<style lang="scss" scoped>
  $filter-bar-width: 250px;

  .search-layout {
    display: flex;
  }
  .search-container {
    flex: 1;
    background-color: white;
    position: relative;
    min-width: 0;
    margin-bottom: 100px;
  }
  .search-head {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: space-between;
    background-color: white;
    z-index: 10;
  }
  .search-head-wing {
    flex: 1 0 160px;
    flex-wrap: nowrap;
  }
  @mixin narrow-search {
    .search-head-wing {
      order: 1;
      flex-basis: auto;
      padding-top: 0.5rem;
    }
  }
  @media (max-width: map-get($grid-breakpoints, 'sm') + $filter-bar-width - 0.02) {
    .show-advanced {
      @include narrow-search
    }
  }
  @include media-breakpoint-down(xs) {
    @include narrow-search
  }
  .icon {
    margin-top: -0.1rem;
    .btn & {
      transition: fill 0.15s ease-in-out;
    }
  }
  .pagination-row {
    margin-top: 50px;
  }

  .filter-bar {
    flex: 0 0 auto;
    overflow: auto;
    -webkit-overflow-scrolling: touch;
    height: 100vh;
    position: sticky;
    top: 0;
    @media all and (-ms-high-contrast:none) {
      // IE overrides, since no `position: sticky`
      height: auto;
    }
    @include media-breakpoint-down(xs) {
      position: fixed;
      height: 100vh; // IE override override
      z-index: ($zindex-fixed + 10); // above SiteNav
      margin-top: 0;
      box-shadow: 0 0 20px rgba(black,0.5);
    }
  }
  .filter-bar-header {
    display: flex;
    align-items: center;
    margin: 0 -20px;
    padding: 0.5rem 12px 0.5rem 20px;
    position: sticky;
    top: 0;
    z-index: 2;
    background-color: $gray-900;
    color: $gray-600;

    .btn-outline-dark {
      border-color: transparent;
      color: $gray-400;
      fill: $gray-400;
      &:hover {
        color: white;
        fill: white;
      }
    }
  }
  .filter-bar-title {
    margin: 0 auto 0 0;
    font-size: 1.1em;
  }
  .filter-bar-content {
    width: $filter-bar-width;
    padding: 0 20px 100px 20px;
    @include media-breakpoint-up(sm) {
      transition: all 0.3s ease;
      .grow-enter &, .grow-leave-to & {
        transform: translateX(-50px);
      }
    }
  }
  .filter-bar-backdrop {
    @include media-breakpoint-down(xs) {
      position: fixed;
      top: 0;
      bottom: 0;
      left: 0;
      right: 0;
      z-index: 15;
      background: rgba(black, 0.2);
    }
    @include media-breakpoint-up(sm) {
      display: none;
    }
  }

  .grow-enter-active,
  .grow-leave-active {
    transition: all 0.3s ease;
    width: $filter-bar-width;
  }
  .grow-enter, .grow-leave-to {
    width: 0;
    @include media-breakpoint-down(xs) {
      width: auto;
      transform: translateX(-$filter-bar-width);
    }
  }
</style>

<template>
  <b-form
    ref="form"
    @submit.stop.prevent="onSubmit"
  >
    <div v-if="errors && errors.non_field_errors">
      <b-alert
        dismissible
        v-for="(error, index) in errors.non_field_errors"
        :key="error"
        v-model="errors.non_field_errors[index]"
        variant="danger"
      >{{error}}</b-alert>
    </div>
    <b-form-group
      v-for="(field, key) in fieldConfigs"
      :key="key"
      :label-cols-sm="labelColsSm"
      :label-cols-lg="labelColsLg"
      :label="isBoolean(field) ? '' : getLabel(field)"
      :label-for="`${formId}-input-${key}`"
      :label-class="{'py-0': isBoolean(field)}"
      :label-align-sm="labelAlignSm"
      :description="field.help_text"
      :invalid-feedback="String(errors && errors[key])"
      :state="errors && errors[key] ? false : undefined"
    >
      <component
        :is="field.component"
        :id="`${formId}-input-${key}`"
        :name="key"
        v-model="value[key]"
        @input="onInput($event, key)"
        @error="onError($event, field, key)"
        v-bind="field.props"
        v-on="field.events"
        :state="errors && errors[key] ? false : undefined"
        :disabled="disabled || field.props.disabled"
      >
        <span v-if="isBoolean(field)">{{getLabel(field)}}</span>
      </component>
    </b-form-group>
    <slot :busy="busy" :disabled="disabled">
      <div class="d-flex justify-content-end align-items-center">
        <slot name="button-sibling" :busy="busy" :disabled="disabled"/>
        <LoadingButton
          type="submit"
          variant="primary"
          :busy="busy"
          :disabled="disabled || busy"
        >
          <slot name="button-content">Submit</slot>
        </LoadingButton>
      </div>
    </slot>
  </b-form>
</template>

<script>
import { uniqueId } from 'lodash'

import DisplayHeader from '@/components/DisplayHeader.vue'
import InputDatetimeLocal from '@/components/InputDatetimeLocal.vue'
import InputImage from '@/components/InputImage.vue'
import InputLocation from '@/components/InputLocation.vue'
import InputMasked from '@/components/InputMasked.vue'
import InputNestedObject from '@/components/InputNestedObject.vue'
import InputSelect from '@/components/InputSelect.vue'
import MultiselectObjects from '@/components/MultiselectObjects.vue'
import OptionsMultiselect from '@/components/OptionsMultiselect.vue'
import { accumulateFocus } from '@/services/accumulateFocus.js'
import { omitBy } from '@/utils/omit.js'
import { fauxOptions } from './OptionsShared.js'

export default {
  name: 'OptionsForm',
  components: {
    DisplayHeader,
    InputDatetimeLocal,
    InputImage,
    InputLocation,
    InputMasked,
    InputNestedObject,
    InputSelect,
    MultiselectObjects,
    OptionsMultiselect,
  },
  props: {
    value: Object,
    idKey: {
      type: String,
      default: 'id',
    },
    fields: Object,
    errors: {
      type: Object,
      default: () => ({}),
    },
    busy: Boolean,
    disabled: Boolean,
    omitFields: {
      type: Function,
      default() {}, // e.g. field => field.read_only
    },
    labelColsSm: {
      type: String,
      default: '4',
    },
    labelColsLg: {
      type: String,
      default: '3',
    },
    labelAlignSm: String,
    labelRequired: {
      type: String,
      default: ' *',
    },
  },
  data() {
    return {
      formId: uniqueId('form-'),
      skipFocusOnce: false,
    }
  },
  computed: {
    fields_() {
      const fields = this.fields || fauxOptions(this.value)
      return omitBy(fields, this.omitFields)
    },
    fieldConfigs() {
      return transformFields(this.fields_)
    },
  },
  watch: {
    value() {
      // clear errors when value changes
      this.$emit('update:errors', {})
    },
    fields: {
      handler(val) {
        // set null values on data, to make the UI happy
        // known issue: this clears errors on fields change, including
        // component creation, because it triggers the 'value' watcher
        if (!val) { return }
        this.$emit('input', {...defaultValues(val), ...this.value})
      },
      immediate: true,
    },
    errors(val) {
      // focus on first element with error
      // we don't want to do this when child components emit an error e.g. multiselect clearing error on search
      // only when updated from the parent e.g. form submission
      const skipFocus = this.skipFocusOnce
      this.skipFocusOnce = false
      if (skipFocus) { return }
      if (!val) { return }
      const keys = Object.keys(val).filter(i => val[i])
      if (keys.length) {
        const selector = keys.map(key => `#${this.formId}-input-${key}`).join()
        accumulateFocus(selector)
      }
    },
  },
  methods: {
    clientValidate() {
      this.$emit('update:errors', {})
      const el = this.$refs.form
      const isValid = !el.reportValidity || el.reportValidity()
      return isValid
    },
    onSubmit() {
      this.$emit('update:errors', {})
      this.$emit('submit')
    },
    onInput(val, key) {
      this.errors[key] = undefined
    },
    onError(err, field, key) {
      if (err && field.formatError) {
        err = field.formatError(err)
      }
      this.skipFocusOnce = true
      return this.$emit('update:errors', {...this.errors, [key]: err})
    },
    getLabel(field) {
      return field.label + (field.required ? this.labelRequired : '')
    },
    isBoolean(field) {
      return field.type?.toLowerCase() === 'boolean'
    },
  }
}

function defaultValues(fields) {
  return Object.keys(fields).reduce((acc, key) => {
    const val = fields[key]
    if (val.type === 'choice') {
      // preselect null values
      acc[key] = null
    }
    return acc
  }, {})
}

function transformFields(fields) {
  return Object.keys(fields).reduce((acc, key) => {
    const val = fields[key]
    acc[key] = {
      ...val,
      ...getFieldConfig(val, key),
    }
    return acc
  }, {})
}
function getFieldConfig(field, key) {
  const baseProps = {
    required: field.required,
    disabled: field.read_only,
  }
  const configs = {
    // Support field types from DRF and some custom ones
    // https://github.com/encode/django-rest-framework/blob/3.14.0/rest_framework/metadata.py#L37-L57
    'header': {
      component: 'DisplayHeader',
      props: baseProps,
    },
    'string': {
      component: 'b-form-input',
      props: baseProps,
    },
    'boolean': {
      component: 'b-form-checkbox',
      props: {...baseProps},
    },
    'datetime': {
      component: 'InputDatetimeLocal',
      props: {...baseProps}
    },
    'date': {
      component: 'b-form-input',
      props: {...baseProps, type: 'date', placeholder: 'YYYY-MM-DD'}
    },
    'email': {
      component: 'b-form-input',
      props: {...baseProps, type: 'email'}
    },
    'password': {
      component: 'b-form-input',
      props: {...baseProps, type: 'password'}
    },
    'integer': {
      component: 'b-form-input',
      props: {...baseProps, type: 'number', min: field.min_value, max: field.max_value}
    },
    'float': {
      component: 'b-form-input',
      props: {...baseProps, type: 'number', step: 'any', min: field.min_value, max: field.max_value}
    },
    'decimal': {
      component: 'b-form-input',
      props: {...baseProps, type: 'number', step: 'any', min: field.min_value, max: field.max_value}
    },
    'masked': {
      component: 'InputMasked',
      props: baseProps,
    },
    'textarea': {
      component: 'b-form-textarea',
      props: {...baseProps, maxRows: 8}
    },
    'file': {
      component: 'b-form-file',
      props: {...baseProps},
    },
    'image upload': {
      component: 'InputImage',
      props: {...baseProps},
    },
    'choice': {
      component: 'InputSelect',
      props: {...baseProps, options: field.choices, textField: 'display_name'},
    },
    'multiple choice': {
      component: 'MultiselectObjects',
      props: {...baseProps, multiple: true, options: field.choices, label: 'display_name', trackBy: 'value', closeOnSelect: false},
    },
    'field': {
      component: 'options-multiselect',
      props: {...baseProps, api: `/api/${key}/`},
      formatError: (err) => `${field.label} lookup error. Please contact customer support. ${err}`,
    },
    'nested object': {
      component: 'InputNestedObject',
      props: {...baseProps, maxRows: 8},
    },
    'location': {
      component: 'InputLocation',
      props: baseProps,
    },
    'list': {
      component: 'b-form-tags',
      props: {...baseProps, separator: ',;', removeOnDelete: true, tagVariant: 'light'},
    },
  }
  const result = configs[field.type?.toLowerCase()] || configs['string']
  Object.assign(result.props, field.props)
  return result
}
</script>

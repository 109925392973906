<template>
  <div class="alert-list">
      <b-alert
        v-for="alert in alerts"
        :key="`alert-${alert.id}`"
        :dismissible="alert.dismissible"
        @dismissed="alert.remove()"
        :variant="alert.variant"
        :show="alert.duration || true"
        fade
        tabindex="-1"
      >
        <div class="alert-message"
          v-html="alert.message"
          :title="textContent(alert.message)"
        ></div>
      </b-alert>
  </div>
</template>

<script>
import VAlert from '.'

export default {
  name: 'VAlertList',
  data() {
    return {
      alerts: VAlert.alerts,
    }
  },
  methods: {
    textContent(html) {
      // from https://stackoverflow.com/a/28900362/490592
      if (!DOMParser) { return html }
      return (new DOMParser).parseFromString(html, 'text/html').documentElement.textContent
    },
  },
}
</script>

<style lang="scss" scoped>
.alert-list {
  background-color: $body-bg;
}
.alert {
  margin-bottom: 0;
  & + & {
    margin-top: 0.25rem;
  }
}
  .alert-message {
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
    white-space: pre-wrap;

    .alert:focus > &,
    .alert:focus-within > & {
      display: block;
    }
  }
</style>

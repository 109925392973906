<template>
  <b-button
    v-bind="$attrs"
    v-on="$listeners"
    class="position-relative"
  >
    <span :class="{'invisible': busy}"><slot/></span>
    <div v-if="busy" class="centered">
      <b-spinner small class="align-middle"/>
    </div>
  </b-button>
</template>

<script>
export default {
  name: 'LoadingButton',
  inheritAttrs: false,
  props: {
    busy: Boolean,
  },
}
</script>

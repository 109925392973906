<!-- grid layout based on https://github.com/xieranmaya/blog/issues/6 -->

<template>
  <section
    @keyup.stop.left="onLeft"
    @keyup.stop.right="onRight"
    @keyup.stop.escape="onEscape"
  >
    <div v-for="(results, key) in groups_" :key="key">
    <slot
      v-if="key !== 'results'"
      name="group-header"
      :title="key"
    >
      <hr>
      <h6>{{key}}</h6>
    </slot>
    <div class="results flex-wrap" :class="{'flex-sm-nowrap': !wrap}">
      <router-link class="result"
        v-for="result in results"
        :key="result.id"
        :to="getTo(result) || ''"
        :id="result.id"
        :style="`width: ${(result.max_width || 1)*200/(result.max_height || 1)}px; flex-grow:${(result.max_width || 1)*200/(result.max_height || 1)}`"
        event=""
        @click.native.exact.prevent="onSelect($event, result)"
        tabindex="-1"
      >
        <div class="result-pad" :style="`padding-bottom:${(result.max_height || 1)/(result.max_width || 1)*100}%`"></div>
        <MediaGridItem
          :item="result"
          :show-status="showStatus"
        >
          <template #extra>
            <slot name="item-extra" :is-open="selectedItem === result && showSelected"></slot>
          </template>
        </MediaGridItem>
      </router-link>
      <div v-if="!fill" class="result-filler d-none d-sm-block" :class="{'min-w-20': wrap}"></div>
    </div>
    </div>

    <div ref="quickHolder" class="quick-holder">
      <transition name="slide" @after-leave="rendered = false">
        <div v-show="showSelected" ref="quickBox" class="quick-box">
          <div class="caret" :style="caretStyle"></div>
          <slot v-if="!lazy || rendered" :item="selectedItem" :close="close">
            <MediaQuick :item="selectedItem">
              <template #image-sibling>
                <MediaQuickControls
                  @close="onEscape"
                  @previous="onLeft"
                  @next="onRight"
                />
              </template>
            </MediaQuick>
          </slot>
        </div>
      </transition>
    </div>
  </section>
</template>

<script>
import { throttle, flatMap } from 'lodash'

import MediaQuick from '@/components/MediaQuick.vue'
import MediaQuickControls from '@/components/MediaQuickControls.vue'
import MediaGridItem from '@/components/MediaGridItem.vue'
import { mediaBreakpointUp } from '@/utils/breakpoints.js'

export default {
  name: 'MediaGrid',
  components: {
    MediaQuick,
    MediaQuickControls,
    MediaGridItem,
  },
  props: {
    lazy: {
      type: Boolean,
      default: true,
    },
    // wrap prop only affects "sm" and up, layout always wraps at "xs" size
    wrap: {
      type: Boolean,
      default: true,
    },
    fill: Boolean,
    // accepts groups or results, groups takes precedence
    groups: Object,
    results: Array,
    id: String,
    showStatus: null,
    onOpen: Function,
    getTo: {
      type: Function,
      default(item) {
        return item ? `/search?q=${item.local_id}#${item.id}`
                    : ''
      },
    },
  },
  data() {
    return {
      selectedItem: {},
      showSelected: false,
      rendered: false,
      caretStyle: {
        'left': '50%',
      },
    }
  },
  computed: {
    groups_() {
      return this.groups ? this.groups : {results: this.results}
    },
    results_() {
      return this.groups ? flatMap(this.groups) : this.results
    },
  },
  watch: {
    results_: 'onChange',
    id: 'onId',
  },
  mounted() {
    this.onId(this.id)

    // resize behavior for quickbox
    let currentWidth = this.$el.clientWidth
    const duration = 400
    let reopenTimeout = null
    this.onResize = throttle((event, {force} = {}) => {
      // always update currentWidth, even if we return early
      const previousWidth = currentWidth
      currentWidth = this.$el.clientWidth
      // if quickbox is visible
      if (!this.showSelected) { return }
      // and force is true...
      if (!force) {
        // ...or element width has changed
        if (previousWidth === currentWidth) { return }
      }
      // take it out of the layout, so grid reflows correctly
      this.removeBox()
      // put it back when we stop getting resize events
      clearTimeout(reopenTimeout)
      reopenTimeout = setTimeout(() => this.show(this.selectedItem), duration + 100)
    }, duration)
    window.addEventListener('resize', this.onResize);
  },
  destroyed() {
    window.removeEventListener('resize', this.onResize);
  },
  methods: {
    onSelect(event, item) {
        if (this.selectedItem === item && this.showSelected) {
          this.setId(null)
        } else {
          this.setId(item.id)
        }
    },
    onId(id) {
      if (!id) {
        this.close()
      } else {
        const item = this.results_ && this.results_.find(i => i.id === id)
        this.open(item, this.scrollToTop)
      }
    },
    setId(id) {
      this.onId(id)
      this.$emit('update:id', id)
    },
    replaceId(id) {
      this.onId(id)
      this.$emit('replaceState', id)
    },
    onLeft(event) {
      if (isEditingText(event)) { return }
      this.openNext(-1)
    },
    onRight(event) {
      if (isEditingText(event)) { return }
      this.openNext()
    },
    onEscape(event) {
      if (isEditingText(event)) { return }
      this.setId(null)
    },
    onChange(results, oldResults) {
      // when results change, but it's still the same array by reference, re-select the selected item
      // this handles when results are updated or replaced by another component e.g. MediaGridEdit
      const item = results.find(i => i.id === this.selectedItem.id)
      if (item && results === oldResults) {
        this.$nextTick(() => {
          this.show(item)
        })
        return
      }

      // when results change, and id is present, select item with that id.
      // handles external changes like back/forwards history navigation
      if (this.id) {
        this.$nextTick(() => {
          this.onId(this.id)
        })
        return
      }

      // otherwise, remove the quickbox
      this.removeBox()
      this.showSelected = false
    },
    show(item) {
      if (!item) { return }
      const el = document.getElementById(item.id)
      if (!el) { return }
      this.selectItem(item);
      this.insertBox(el);
      this.rendered = true
      this.showSelected = true;
      return el
    },
    close() {
      this.showSelected = false
    },
    open(item, scroll = scrollIfNeeded) {
      if (this.onOpen) {
        return this.onOpen(item)
      }
      const el = this.show(item)
      if (!el) { return }
      setTimeout(() => {
        el.focus()
        scroll(el)
      })
    },
    openNext(advance = 1) {
      if (!this.results_) { return }
      const index = this.results_.findIndex(i => i.id === this.selectedItem.id)
      const result = this.results_[index + advance]
      if (result) {
        this.open(result, this.scrollToTop)
        this.replaceId(result.id)
      }
    },
    insertBox(target) {
      const box = this.$refs.quickBox

      if (!this.wrap && mediaBreakpointUp('sm')) {
        // When wrapping is off, place quickbox below the results box.
        const results = target.parentNode
        results.parentNode.insertBefore(box, results.nextSibling) // insertAfter
      } else {
        // When wrapping is on, place quickbox after current row.
        target.parentNode.insertBefore(box, getStartOfNextRow(target))
      }

      const pointer = target.offsetLeft + target.getBoundingClientRect().width/2;
      this.caretStyle = {
        'left': `${pointer}px`,
      }
    },
    removeBox() {
      this.$refs.quickHolder.appendChild(this.$refs.quickBox)
    },
    selectItem(result) {
      this.selectedItem = result;
    },
    scrollToTop(el) {
      // native scrollIntoView is not sufficient because it does not allow an offset
      const {y} = el.getBoundingClientRect()
      const { navHeight } = this.$store.state.appearance
      window.scrollBy({top: y - parseInt(navHeight) - 8, left: 0, behavior: 'smooth'})
    },
  }
}

function scrollIfNeeded(el) {
  el.scrollIntoView({block: 'nearest', inline: 'nearest'})
}

function isEditingText(event) {
  return event && event.target && ['input', 'textarea'].includes(event.target.tagName.toLowerCase())
}

function getStartOfNextRow(target) {
  // Return the first element in the next row, or undefined if there is no next row.
  const siblings = target.parentNode.children
  const left = siblings[0].offsetLeft
  const index = Array.prototype.indexOf.call(siblings, target)

  let insertionPoint
  for (let i = index + 1; i < siblings.length; i++) {
    if (siblings[i].offsetLeft === left) {
      insertionPoint = i
      break
    }
  }
  return siblings[insertionPoint]
}
</script>

<style lang="scss" scoped>
  $margin: 0.25rem;

  .results {
    position: relative;
    display: flex;
    text-align: left;
  }
  .result {
    position: relative;
    margin: $margin;
    /* TODO: remove `overflow: hidden` when all images have accurate
    dimensions/aspect ratios. See corresponding TODO in ArtistInfo.vue */
    overflow: hidden;
    background-color: $light;
    transition: border-radius .15s ease-in-out, box-shadow .15s ease-in-out; // based on $input-transition
    &:focus {
      outline: 0;
      border-radius: $border-radius;
      // simulate the border + box-shadow look of focused inputs, based on $input-focus-box-shadow
      box-shadow: 0 0 0 $input-border-width $input-focus-border-color,
                  0 0 0 ($input-btn-focus-width + ($input-border-width / 16px)) $input-btn-focus-color;
    }
  }
  .result-filler {
    flex-grow: 1e4;
  }
  .min-w-20 {
    min-width: 20%;
  }

  .quick-holder {
    display: none;
  }
  .quick-box {
    width: 100%;
    margin: $margin;
    background: #333;
    color: white;
    position: relative;
    .caret {
      bottom: 100%;
      left: 50%;
      border: solid transparent;
      height: 0;
      width: 0;
      position: absolute;
      pointer-events: none;
      border-color: transparent;

      border-bottom-color: #333;
      border-width: $margin * 2;
      margin-left: $margin * -2;
    }
  }

  .slide-enter-active,
  .slide-leave-active {
    transition: all 0.3s;
    max-height: 600px;
  }
  .slide-enter,
  .slide-leave-to {
    opacity: 0;
    max-height: 0;
    margin: 0 $margin;
  }
</style>

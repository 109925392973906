import Vue from 'vue'
import VueRouter from 'vue-router'
import { pick, truncate } from 'lodash'

import store from './store'

import ManageArtistIdentityRoutes from './router/manage/artist-identity.js'

import Wrapper from './layouts/default.vue'
import MessageOverlay from './components/MessageOverlay.vue'
import Accounts from './pages/accounts.vue'
import AccountsLogin from './pages/accounts/login.vue'
import AccountsRegister from './pages/accounts/register.vue'
import AccountsResetPassword from './pages/accounts/reset-password.vue'
import AccountsResetPasswordConfirm from './pages/accounts/reset-password-confirm.vue'
import AccountsLogout from './pages/accounts/logout.vue'
import Cart from './pages/cart.vue'
import Search from './pages/search.vue'
import { paramsSerializer } from './services/http'
import VAlert from './features/VAlert'
import { SKIN, SITE_TITLE } from './services/constants.js'

Vue.use(VueRouter)

const routes = [
  { path: '',
    component: Wrapper,
    meta: { title: SITE_TITLE },
    children: [
      {
        path: '/',
        name: 'index',
        component: () => {
          switch (SKIN) {
            case 'gov':   return import(/* webpackChunkName: 'gov' */ './pages/index-gov.vue')
            default:      return import(/* webpackChunkName: 'gaa' */ './pages/index.vue')
          }
        },
      },
      {
        path: '/accounts',
        redirect: '/accounts/login',
        component: Accounts,
        children: [
          {
            path: 'login',
            name: 'accounts-login',
            component: AccountsLogin,
            props: route => ({...route.params, ...route.query}),
            meta: { title: 'Sign in - Great American Art' },
          },
          {
            path: 'register',
            name: 'accounts-register',
            component: AccountsRegister,
            props: route => ({...route.params, ...route.query}),
            meta: { title: 'Sign up - Great American Art' },
          },
          {
            path: 'reset-password',
            name: 'accounts-reset-password',
            component: AccountsResetPassword,
            props: route => ({...route.params, ...route.query}),
            meta: { title: 'Reset Password - Great American Art' },
          },
          {
            path: 'reset-password-confirm/:uid?/:token?',
            name: 'accounts-reset-password-confirm',
            component: AccountsResetPasswordConfirm,
            props: route => ({...route.params, ...route.query}),
            meta: { title: 'Set Password - Great American Art' },
          },
        ],
      },
      {
        path: '/accounts/logout',
        name: 'accounts-logout',
        component: AccountsLogout,
        props: route => ({...route.params, ...route.query}),
      },
      { path: '/artists', redirect: '/artist' },
      {
        path: '/artist',
        component: {render(h) { return h('router-view') }},
        meta: { title: 'Artist Portal - Great American Art' },
        children: [
          {
            path: 'apply',
            component: () => import(/* webpackChunkName: 'artist' */ './pages/artist/apply.vue'),
            meta: { title: 'Apply to the Artist Portal - Great American Art' },
            children: [
              {
                path: '',
                name: 'artist-apply',
                component: () => import(/*webpackChunkName: 'artist' */ './pages/artist/apply/index.vue'),
              },
              {
                path: 'login',
                name: 'artist-login',
                component: () => import(/*webpackChunkName: 'artist' */ './pages/artist/apply/login.vue'),
              },
              {
                path: 'info',
                name: 'artist-info',
                component: () => import(/*webpackChunkName: 'artist' */ './pages/artist/apply/info.vue'),
                meta: { auth: true },
              },
              {
                path: 'sample',
                name: 'artist-sample',
                component: () => import(/*webpackChunkName: 'artist' */ './pages/artist/apply/sample.vue'),
                meta: { auth: true },
              },
              {
                path: 'applied',
                name: 'artist-applied',
                component: () => import(/*webpackChunkName: 'artist' */ './pages/artist/apply/applied.vue'),
                meta: { auth: true },
              },
            ],
          },
          {
            path: 'message',
            name: 'artist-message',
            component: () => import(/*webpackChunkName: 'artist' */ './pages/artist/message.vue'),
          },
          {
            path: 'new',
            component: () => import(/*webpackChunkName: 'artist' */ './pages/artist/new.vue'),
            meta: { auth: true, title: 'New Artist - Great American Art' },
            children: [
              {
                path: '',
                name: 'artist-new',
                component: () => import(/*webpackChunkName: 'artist' */ './pages/artist/new/index.vue'),
              },
              {
                path: 'royalty',
                name: 'artist-royalty',
                component: () => import(/*webpackChunkName: 'artist' */ './pages/artist/new/royalty.vue'),
              },
              {
                path: 'tax',
                name: 'artist-tax',
                component: () => import(/*webpackChunkName: 'artist' */ './pages/artist/new/tax.vue'),
              },
              {
                path: 'payment',
                name: 'artist-payment',
                component: () => import(/*webpackChunkName: 'artist' */ './pages/artist/new/payment.vue'),
                props: route => ({...route.params, ...route.query}),
              },
            ],
          },
          {
            path: '',
            component: () => import(/*webpackChunkName: 'artist' */ './pages/artist.vue'),
            children: [
              {
                path: '',
                name: 'artist',
                component: () => import(/*webpackChunkName: 'artist' */ './pages/artist/index.vue'),
              },
              {
                path: 'art',
                component: {render(h) { return h('router-view') }},
                meta: { auth: true },
                children: [
                  {
                    path: '',
                    name: 'artist-art',
                    component: () => import(/*webpackChunkName: 'artist' */ './pages/artist/art/index.vue'),
                    props: route => ({...route.params, ...route.query}),
                  },
                  {
                    path: 'all',
                    name: 'artist-art-all',
                    component: () => import(/*webpackChunkName: 'artist' */ './pages/manage/image/-all.vue'),
                    props: route => ({
                      artist: store.state.user.artist?.id,
                      ...route.params,
                      ...route.query,
                    }),
                  },
                  {
                    path: ':id',
                    name: 'artist-art-id',
                    component: () => import(/*webpackChunkName: 'artist' */ './pages/artist/art/_id.vue'),
                    props: route => ({...route.params, ...route.query}),
                  },
                ],
              },
              {
                path: 'profile',
                name: 'artist-profile',
                component: () => import(/*webpackChunkName: 'artist' */ './pages/artist/profile/index.vue'),
                meta: { auth: true },
              },
              {
                path: 'profile/edit',
                name: 'artist-profile-edit',
                component: () => import(/*webpackChunkName: 'artist' */ './pages/artist/profile/edit.vue'),
                meta: { auth: true },
              },
              {
                path: 'upload/:advanced?',
                name: 'artist-upload',
                component: () => import(/*webpackChunkName: 'artist' */ './pages/artist/upload.vue'),
                meta: { auth: true },
                props: true,
              },
              {
                path: 'account',
                component: () => import(/*webpackChunkName: 'artist' */ './pages/artist/account.vue'),
                meta: { auth: true },
                children: [
                  {
                    path: '',
                    name: 'artist-account',
                    component: () => import(/*webpackChunkName: 'artist' */ './pages/artist/account/index.vue'),
                  },
                  {
                    path: 'royalty',
                    name: 'artist-account-royalty',
                    component: () => import(/*webpackChunkName: 'artist' */ './pages/artist/account/royalty.vue'),
                  },
                  {
                    path: 'tax',
                    name: 'artist-account-tax',
                    component: () => import(/*webpackChunkName: 'artist' */ './pages/artist/account/tax.vue'),
                  },
                  {
                    path: 'payment',
                    name: 'artist-account-payment',
                    component: () => import(/*webpackChunkName: 'artist' */ './pages/artist/account/payment.vue'),
                    props: route => ({...route.params, ...route.query}),
                  },
                ],
              },
            ],
          },
        ],
      },
      {
        path: '/:type(projects)',
        component: {render(h) { return h('router-view') }},
        children: [
          {
            path: '',
            name: 'projects',
            component: require('./pages/projects/index.vue').default,
            props: true,
            meta: { title: 'Projects - Great American Art' },
          },
          {
            path: ':id',
            name: 'projects-detail',
            component: require('./pages/projects/_id/index.vue').default,
            props: true,
          },
          {
            path: ':id/checkout',
            name: 'projects-detail-checkout',
            component: () => import(/* webpackChunkName: 'checkout' */ './pages/projects/_id/checkout.vue'),
            props: true,
            meta: { title: 'Checkout - Great American Art' },
          }
        ],
      },
      {
        path: '/:type(orders)',
        component: {render(h) { return h('router-view') }},
        children: [
          {
            path: '',
            name: 'orders',
            component: () => import(/* webpackChunkName: 'checkout' */ './pages/orders/index.vue'),
            props: route => ({
              ...route.params,
              getName: item => `Order #${item.order_id}`,
            }),
            meta: { title: 'Orders - Great American Art' },
          },
          {
            path: ':id',
            name: 'orders-detail',
            component: () => import(/* webpackChunkName: 'checkout' */ './pages/orders/_id/index.vue'),
            props: route => ({
              ...route.params,
              getName: item => `Order #${item.order_id}`,
            }),
            meta: { title: 'Order - Great American Art' },
          },
        ],
      },
      {
        path: '/cart',
        name: 'cart',
        component: Cart,
        meta: { title: 'Cart - Great American Art' },
      },
      {
        path: '/checkout',
        name: 'checkout',
        component: () => import(/* webpackChunkName: 'checkout' */ './pages/checkout.vue'),
        meta: { title: 'Checkout - Great American Art' },
      },
      {
        path: '/checkout/confirmation/:id?',
        name: 'checkout-confirmation',
        component: () => import(/*webpackChunkName: 'checkout' */ './pages/checkout/confirmation.vue'),
        props: true,
        meta: { title: 'Order Confirmation - Great American Art' },
      },
      {
        path: '/manage/image',
        name: 'manage-type-image',
        component: () => import(/* webpackChunkName: 'manage' */ './pages/manage/image/index.vue'),
        props: route => ({...route.query}),
        meta: { auth: 'staff', title: 'Images - Great American Art' },
      },
      {
        path: '/manage/image/:id',
        name: 'manage-image',
        component: () => import(/* webpackChunkName: 'manage' */ './pages/manage/image/_id/index.vue'),
        props: route => ({...route.params, ...route.query}),
        meta: { auth: 'staff', title: 'Image - Great American Art' },
      },
      {
        path: '/manage/image/:id/edit',
        name: 'manage-image-edit',
        component: () => import(/* webpackChunkName: 'manage' */ './pages/manage/image/_id/edit.vue'),
        props: route => ({...route.params, ...route.query}),
        meta: { auth: 'staff', title: 'Edit Image - Great American Art' },
      },
      {
        path: '/manage/:type(upload)/new',
        name: 'manage-upload-new',
        component: () => import(/* webpackChunkName: 'manage' */ './pages/manage/upload/new/index.vue'),
        meta: { auth: 'staff', title: 'Upload - Great American Art' },
      },
      {
        path: '/manage/tax-reporting/:id?',
        name: 'manage-tax-reporting',
        component: () => import(/* webpackChunkName: 'manage' */ './pages/manage/artist-identity/tax.vue'),
        props: route => ({...route.params, ...route.query}),
        meta: { auth: 'staff', title: 'Tax Reporting - Great American Art' },
      },
      {
        path: '/manage',
        component: () => import(/* webpackChunkName: 'manage' */ './pages/manage.vue'),
        meta: { auth: 'staff', title: 'Manage - Great American Art' },
        children: [
          {
            path: '',
            name: 'manage',
            component: () => import(/* webpackChunkName: 'manage' */ './pages/manage/index.vue'),
          },
          {
            path: ':type(art-usage)',
            name: 'manage-type-art-usage',
            component: () => import(/* webpackChunkName: 'manage' */ './pages/manage/art-usage/index.vue'),
            props: route => ({...route.params, ...route.query}),
          },
          {
            path: ':type(artist-profile)',
            name: 'manage-type-artist-profile',
            component: () => import(/* webpackChunkName: 'manage' */ './pages/manage/artist-profile/index.vue'),
            props: route => ({
              ...route.params,
              ...route.query,
              fields: [
                {key: 'full_name', sortable: true},
                {key: 'bio', class: 'd-none d-lg-table-cell', formatter: i => truncate(i, {length: 30})},
                {key: 'email', class: 'd-none d-xl-table-cell'},
                {key: 'approved', sortable: true, class: 'd-none d-sm-table-cell', formatter: i => i ? '✓' : i===false ? '✗' : ''},
                {key: 'created', sortable: true, class: 'd-none d-sm-table-cell'},
              ],
            }),
          },
          {
            path: ':type(artist-profile)/email',
            name: 'manage-type-artist-profile-email',
            component: () => import(/* webpackChunkName: 'manage' */ './pages/manage/artist-profile/email.vue'),
            props: route => ({...route.params, ...route.query}),
          },
          {
            path: ':type(artist)',
            name: 'manage-type-artist',
            component: () => import(/* webpackChunkName: 'manage' */ './pages/manage/_type/index.vue'),
            props: route => ({
              ...route.params,
              ...route.query,
              fields: [
                {key: 'name', sortable: true},
                {key: 'gaa_artist', label: 'Portal', sortable: true, class: 'd-none d-sm-table-cell', formatter: v => v ? '✓' : ''},
                {key: 'created', sortable: true, class: 'd-none d-sm-table-cell'},
              ],
              tbodyTrClass: item => ({'text-muted': !item?.active}),
            }),
          },
          {
            path: ':type(upload)',
            name: 'manage-type-upload',
            component: () => import(/* webpackChunkName: 'manage' */ './pages/manage/upload/index.vue'),
            props: route => ({
              ...route.params,
              ...route.query,
              fields: [
                {key: 'name', sortable: true},
                {key: 'approved', sortable: true, class: 'd-none d-sm-table-cell', formatter: i => i ? '✓' : i===false ? '✗' : ''},
                {key: 'published', sortable: true, class: 'd-none d-sm-table-cell', formatter: v => v ? '✓' : ''},
                {key: 'created', sortable: true, class: 'd-none d-sm-table-cell'},
              ],
            }),
          },
          {
            path: ':type(artist-identity)',
            name: 'manage-type-artist-identity',
            component: () => import(/* webpackChunkName: 'manage' */ './pages/manage/artist-identity/index.vue'),
            props: route => ({
              ...route.params,
              ...route.query,
              fields: [
                {key: 'name', sortable: true, sortByFormatted: true, formatter: (val, key, i) => i.businessName ? i.businessName : `${i.firstName} ${i.lastName}`},
                {key: 'email', sortable: true, class: 'd-none d-sm-table-cell'},
                {key: 'status', sortable: true, class: 'd-none d-sm-table-cell'},
                {key: 'createdOn', sortable: true, class: 'd-none d-sm-table-cell'},
              ],
            }),
          },
          {
            path: ':type(artist-payment)',
            name: 'manage-type-artist-payment',
            component: () => import(/* webpackChunkName: 'manage' */ './pages/manage/artist-payment/index.vue'),
            props: route => ({...route.params, ...route.query}),
          },
          {
            path: 'payment-due',
            name: 'manage-type-payment-due',
            component: () => import(/* webpackChunkName: 'manage' */ './pages/manage/payment-due/index.vue'),
            props: route => ({...route.params, ...route.query}),
            meta: { title: 'Payments Due - Great American Art' },
          },
          {
            path: ':type',
            name: 'manage-type',
            component: () => import(/* webpackChunkName: 'manage' */ './pages/manage/_type/index.vue'),
            props: route => ({...route.params, ...route.query}),
          },
          {
            path: ':type(artist-profile)/new',
            name: 'manage-artist-profile-new',
            component: () => import(/* webpackChunkName: 'manage' */ './pages/manage/artist-profile/_id/edit.vue'),
            props: true,
          },
          {
            path: ':type(artist-identity)/new',
            name: 'manage-artist-identity-new',
            component: () => import(/* webpackChunkName: 'manage' */ './pages/manage/artist-identity/_id/edit.vue'),
            props: true,
          },
          {
            path: ':type(artist)/new',
            name: 'manage-artist-new',
            component: () => import(/* webpackChunkName: 'manage' */ './pages/manage/artist/_id/edit.vue'),
            props: true,
          },
          {
            path: ':type/new',
            name: 'manage-item-new',
            component: () => import(/* webpackChunkName: 'manage' */ './pages/manage/_type/_id/edit.vue'),
            props: true,
          },
          {
            path: ':type(art-usage)/:id',
            name: 'manage-art-usage',
            component: () => import(/* webpackChunkName: 'manage' */ './pages/manage/art-usage/_id/index.vue'),
            props: true,
          },
          {
            path: ':type(artist-profile)/:id',
            name: 'manage-artist-profile',
            component: () => import(/* webpackChunkName: 'manage' */ './pages/manage/artist-profile/_id/index.vue'),
            props: true,
          },
          {
            path: ':type(artist-identity)/:id',
            name: 'manage-artist-identity',
            component: () => import(/* webpackChunkName: 'manage' */ './pages/manage/artist-identity/_id/index.vue'),
            props: route => ({
              ...route.params,
              nameKey: 'email',
            }),
          },
          {
            path: ':type(artist-payment)/:id',
            name: 'manage-artist-payment',
            component: () => import(/* webpackChunkName: 'manage' */ './pages/manage/artist-payment/_id/index.vue'),
            props: true,
          },
          {
            path: ':type(artist-payment)/:id/status-transitions',
            name: 'manage-type-payment-status-transition',
            component: () => import(/*webpackChunkName: 'manage' */ '@/pages/manage/artist-payment/_id/status-transitions/index.vue'),
            props: route => ({
              ...route.params,
              ...route.query,
              nameKey: 'token',
            }),
          },
          {
            path: ':type(artist-payment)/:id/status-transitions/:stsToken',
            name: 'manage-payment-status-transition',
            component: () => import(/*webpackChunkName: 'manage' */ '@/pages/manage/artist-payment/_id/status-transitions/_stsToken.vue'),
            props: route => ({
              ...route.params,
              nameKey: 'token',
            }),
          },
          {
            path: ':type(artist)/:id',
            name: 'manage-artist',
            component: () => import(/* webpackChunkName: 'manage' */ './pages/manage/artist/_id/index.vue'),
            props: true,
          },
          {
            path: ':type(upload)/:id',
            name: 'manage-upload',
            component: () => import(/* webpackChunkName: 'manage' */ './pages/manage/upload/_id/index.vue'),
            props: true,
          },
          {
            path: ':type/:id',
            name: 'manage-item',
            component: () => import(/* webpackChunkName: 'manage' */ './pages/manage/_type/_id/index.vue'),
            props: true,
          },
          {
            path: ':type(artist-profile)/:id/edit',
            name: 'manage-artist-profile-edit',
            component: () => import(/* webpackChunkName: 'manage' */ './pages/manage/artist-profile/_id/edit.vue'),
            props: true,
          },
          {
            path: ':type(artist-identity)/:id/edit',
            name: 'manage-artist-identity-edit',
            component: () => import(/* webpackChunkName: 'manage' */ './pages/manage/artist-identity/_id/edit.vue'),
            props: true,
          },
          {
            path: ':type(artist)/:id/edit',
            name: 'manage-artist-edit',
            component: () => import(/* webpackChunkName: 'manage' */ './pages/manage/artist/_id/edit.vue'),
            props: true,
          },
          {
            path: ':type/:id/edit',
            name: 'manage-item-edit',
            component: () => import(/* webpackChunkName: 'manage' */ './pages/manage/_type/_id/edit.vue'),
            props: true,
          },

          ...ManageArtistIdentityRoutes,
        ],
      },
      { path: '/search',
        name: 'search',
        component: Search,
        props: route => Object.assign({}, route.params, route.query),
        meta: { title: route => (route.query.q ? route.query.q + ' - ' : '') + 'Great American Art Search' },
      },
      { path: '/images/:id',
        name: 'image-detail',
        component: require('./pages/images/_id.vue').default,
        props: route => ({...route.params, ...route.query}),
      },
      {
        path: '*',
        name: 'error',
        component: MessageOverlay,
        props: {
          messages: [{title: '404', description: 'Not Found'}],
        },
      },
    ]
  },
]

const scrollBehavior = function (to, from, savedPosition) {
  if (savedPosition) {
    return savedPosition
  }
  const urlChanged = to.fullPath.replace(to.hash, '') !== from.fullPath.replace(from.hash, '')
  if (urlChanged) {
    return { x: 0, y: 0 }
  }
}

const router = new VueRouter({
  mode: 'history',
  routes,
  scrollBehavior,
})

router.afterEach((to, from) => {
  // clear errors on navigation
  const isFirstLoad = from.name === null
  if (!isFirstLoad) {
    store.dispatch('errors/clearError')
    VAlert.reset()
  }

  // make route info available for error handling
  const nearestWithAuth = to.matched.slice().reverse().find(r => r.meta && r.meta.auth)
  store.dispatch('errors/setRouteRequiresAuth', nearestWithAuth && pick(nearestWithAuth, ['name', 'path', 'meta']))
})

router.afterEach((to, from) => {
  // Set page title
  // https://github.com/vuejs/vue-router/issues/914#issuecomment-384477609

  // Preserve titles set by components.
  if (to.path === from.path && typeof to.meta?.title !== 'function') { return }

  // This goes through the matched routes from last to first, finding the closest route with a title.
  // eg. if we have /some/deep/nested/route and /some, /deep, and /nested have titles, nested's will be chosen.
  // https://alligator.io/vuejs/vue-router-modify-head/
  const nearestWithTitle = to.matched.slice().reverse().find(r => r.meta && r.meta.title)

  if (nearestWithTitle) {
    let title
    if (typeof nearestWithTitle.meta.title === 'function') {
      title = nearestWithTitle.meta.title(to)
    } else {
      title = nearestWithTitle.meta.title;
    }
    Vue.nextTick(() => {
      document.title = title
    })
  }
})

router.onError((err) => {
  store.dispatch('errors/setError', err)
  console.error(err) // throwing error would stop other onError callbacks from running, so use console.error
})

router.onReady(function () {
  // placed in onReady so it doesn't trigger on page load
  router.afterEach(analyticsGuard)
})

function analyticsGuard(to, from) {
  // Google Analyics track pageviews

  // whitelist query params for inclusion
  const allowedParams = [
    'q',
  ]

  // serialize url
  const [toUrl, fromUrl] = [to, from]
    .map(route => ({...route, query: pick(route.query, allowedParams)}))
    .map(route => {
      const qs = paramsSerializer(route.query).toString()
      return route.path + (qs ? '?'+qs : '')
    })

  // don't send pageview if locations match
  if (toUrl === fromUrl) { return }

  /* global dataLayer */
  dataLayer.push({
    event: 'pageview',
    page: {
      path: toUrl,
    },
  });

  // Drift Chat track navigation
  /* global drift */
  setTimeout(() => {
    drift.page()
  })
}

export default router

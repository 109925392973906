<template>
  <div
    class="hide-button"
    :class="{faded: disabled}"
    @click.prevent.stop="toggleHide"
    v-b-tooltip.hover
    :title="(title) ? title : (published) ? 'Unpublish' : 'Publish'"
    :key="(published) ? 'shown' : 'hidden'"
  >
    <IconCircleSlash
      class="icon"
      v-if="published"
      key="shown"
    />
    <IconEye
      class="icon"
      v-else
      key="hidden"
    />
  </div>
</template>

<script>
import { http } from '@/services/http.js'

import IconEye from '@primer/octicons/build/svg/eye-16.svg'
import IconCircleSlash from '@primer/octicons/build/svg/circle-slash-16.svg'

export default {
  name: 'HideButton',
  components: {
    IconEye,
    IconCircleSlash,
  },
  props: {
    id: String,
    published: Boolean,
    disabled: Boolean,
    title: String,
  },
  methods: {
    async toggleHide() {
      if (this.disabled) { return }
      try {
        const publish = !this.published
        await http.patch(`/content/edit/${this.id}/`, {
          publish,
        })
        this.$emit('update:published', publish)
        const status = (publish) ? 'published' : 'unpublished'
        const variant = (publish) ? 'success' : 'secondary'
        this.$bvToast.toast(`${this.id} ${status}`, {variant})
      }
      catch (err) {
        this.$alert.simple(`${err}\nPlease try again. Contact customer support if this persists.`, {variant: 'danger'})
      }
    },
  }
}
</script>

<style lang="scss" scoped>
.hide-button {
  cursor: pointer;
  width: 16px;
  text-align: center;

  &.faded {
    opacity: $btn-disabled-opacity;
  }
}
.icon {
  fill: white;
}
</style>

<template>
  <div
    class="butt"
    :class="{disabled: busy}"
    @click.prevent.stop="toggle"
    v-b-tooltip.hover
    :title="`Add to ${collection} static items`"
  >
    <b-spinner small
      v-if="busy"
      class="align-middle"
    />
    <IconCheck
      v-else-if="active"
    />
    <IconTag
      v-else
      class="icon-tag"
    />
    <span v-if="collection !== 'government'" class="icon-label">{{collection[0]}}</span>
  </div>
</template>

<script>
import { http2 } from '@/services/http.js'

import IconTag from '@primer/octicons/build/svg/tag-16.svg'
import IconCheck from '@primer/octicons/build/svg/check-16.svg'

export default {
  name: 'StaticButton',
  components: {
    IconTag,
    IconCheck,
  },
  props: {
    value: Object,
    collection: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      active: false,
      busy: false,
    }
  },
  methods: {
    async toggle() {
      const active = !this.active
      if (active) {
        await this.save(this.value)
        this.$bvToast.toast(`Added to ${this.collection} static items`, {variant: 'success', noCloseButton: true, solid: true})
        this.active = active
      } else {
        this.$bvToast.toast(`In ${this.collection} static items`, {variant: 'secondary', noCloseButton: true, solid: true})
      }
    },
    save(val) {
      if (this.busy) { throw new Error('Busy') }
      this.busy = true
      return http2.post('/api/v2/designstudio/', {
        imagedata: val,
        action: 'add-static',
        collection: this.collection,
      })
        .catch(err => {
          this.$alert.simple(`${err}\nPlease try again. Contact customer support if this persists.`, {variant: 'danger'})
          throw err
        })
        .finally(() => this.busy = false)
    }
  },
}
</script>

<style lang="scss" scoped>
.butt {
  position: relative;
  cursor: pointer;
  height: 16px;
  text-align: center;
  fill: currentColor;
}
.disabled {
  opacity: $btn-disabled-opacity;
  pointer-events: none;
}
.icon-tag {
  transform: scale(1.1);
  transform-origin: 0 0;
}
.icon-label {
  position: absolute;
  left: 7px;
  top: 9px;
  transform: rotate(45deg);
  font-size: 8px;
  font-weight: bold;
  text-transform: uppercase;
}
</style>

export const IS_PROD = process.env.NODE_ENV === 'production'

const SKINS = {
  'gapco.thesametime.com': 'gov',
  'design.staging.greatamericangovart.com': 'gov',
  'greatamericangovart.com': 'gov',
  'design.greatamericangovart.com': 'gov',
}
export const SKIN = SKINS[location.hostname]

const SITE_TITLES = {
  'gov': 'Great American Art for Government',
}
export const SITE_TITLE = SITE_TITLES[SKIN] ?? 'Great American Art'

export const SEARCH_LIMIT_DEFAULT = 100
export const MANAGE_LIMIT_DEFAULT = 100
export const CART_LIMIT_DEFAULT = 50
export const APP_LIMIT_DEFAULT = 20

export let UI_URL, API_URL, SEARCH_URL, AUTH_URL, HOME_URL

if (location.hostname === 'design.staging.greatamericanart.com'
 || location.hostname === 'design.staging.greatamericangovart.com') {
  UI_URL =     'https://design.staging.greatamericanart.com/'
  API_URL =    'https://app.staging.greatamericanart.com/'
  SEARCH_URL = 'https://search.greatamericanart.com/'
  AUTH_URL =   'https://app.staging.greatamericanart.com/signin/'
  HOME_URL =   SKIN ? '/' : 'https://app.staging.greatamericanart.com/'
} else if (IS_PROD) {
  UI_URL =     'https://design.greatamericanart.com/'
  API_URL =    'https://app.greatamericanart.com/'
  SEARCH_URL = 'https://search.greatamericanart.com/'
  AUTH_URL =   'https://app.greatamericanart.com/signin/'
  HOME_URL =   SKIN ? '/' : 'https://greatamericanart.com/'
} else {
  UI_URL =     'http://design.devgapco.com:8080/'
  API_URL =    'http://app.devgapco.com:8001/'
  SEARCH_URL = 'http://search.devgapco.com:8000/'
  AUTH_URL =   'http://app.devgapco.com:8001/signin/'
  HOME_URL =   '/'
}

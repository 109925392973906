import { http, http2, appApiCreds, authApi, authApiCreds, debouncedLogoutAlert } from '@/services/http.js'

// initial state
const state = {
  token: null,
  user: null,

  profile: null,

  artistProfile: null,
  artist: null,
}

// getters
const getters = {
  isStaff(state) {
    return state.user && state.user.is_staff
  },
  isSession(state) {
    return state.profile && !state.user
  },
  currentProject(state) {
    return state.profile?.current_project
  },
}

// actions
const actions = {
  async login({ dispatch }, credentials) {
    await dispatch('clear')
    await dispatch('fetchToken', credentials)
    await dispatch('fetchMulti')
    dispatch('cart/fetchCartStart', null, {root: true})
  },
  register(context, credentials) {
    return authApi.post('/auth/users/', credentials)
  },
  resetPassword(context, credentials) {
    return authApi.post('/auth/users/reset_password/', credentials)
  },
  resetPasswordConfirm(context, credentials) {
    return authApi.post('/auth/users/reset_password_confirm/', credentials)
  },
  fetchToken({ commit }, credentials) {
    return authApiCreds.post('/auth/jwt/create/', credentials)
      .then(response => commit('setToken', response.data))
  },
  fetchTokenExisting({ commit }) {
    return authApiCreds.get('/auth/jwt/get/')
      .then(response => commit('setToken', response.data))
  },
  fetchUser({ commit }) {
    return authApi.get('/auth/users/me/')
      .then(response => commit('setUser', response.data))
  },

  checkAuth({ dispatch }) {
    // check if we are logged in, or have a session (invited users)
    return dispatch('checkAuthToken')
      .catch(() => dispatch('checkAuthSession'))
  },
  checkAuthSession({ commit, dispatch, state }) {
    return dispatch('fetchProfileSession')
      .catch(err => {
        if (err.response?.status === 403 && state.profile) {
          // sync session logout
          commit('setProfile', null)
          commit('cart/clear', null, {root: true})
        }
        throw new Error('Session profile not available.')
      })
  },
  fetchProfileSession({ commit }) {
    return appApiCreds.get('/api/v2/profile/')
      .then(response => commit('setProfile', response.data))
  },

  async checkAuthToken({ commit, dispatch, state }) {
    // sync login, logout, or change of user from APP

    // check for a token
    await dispatch('fetchTokenExisting')
      .catch(err => {
        if (err.response && err.response.status === 401) {
          // if we're denied...
          if (state.token) {
            // and we were previously logged in, sync logout
            commit('clear')
            commit('cart/clear', null, {root: true})
            debouncedLogoutAlert()
          }
        }
        if (!state.token) {
          throw new Error('No token.')
        }
      })
    // if we get a token, update local user data from server
    return dispatch('fetchMulti')
      .catch(() => {
        throw new Error('User not available.')
      })
  },
  fetchMulti({ dispatch }) {
    return Promise.all([
      dispatch('fetchUser')
        .then(() => dispatch('fetchArtistProfile').catch(() => {})),
      dispatch('fetchProfile').catch(() => {}),
    ])
  },

  fetchProfile({ commit }) {
    return http2.get('/api/v2/profile/')
      .then(response => commit('setProfile', response.data))
  },

  fetchArtistProfile({ commit, state }) {
    if (!state.user) { return Promise.reject(new Error('Cannot fetch artist profile. Not logged in.')) }
    return http.get('/api/artist-profile/', {params: {user_uuid: state.user.uuid}})
      .then(response => commit('setArtistProfile', response.data.results[0]))
  },
  fetchArtist({ commit, state }) {
    if (!state.artistProfile) { return Promise.reject(new Error('Cannot fetch artist. No artist profile.')) }
    return http.get('/api/artist/', {params: {gaa_artist: state.artistProfile.id}})
      .then(response => commit('setArtist', response.data.results[0]))
  },
  async cachedArtistProfile({ dispatch, state }) {
    if (!state.artistProfile) {
      await dispatch('fetchArtistProfile')
    }
    return state.artistProfile
  },
  async cachedArtist({ dispatch, state }) {
    await dispatch('cachedArtistProfile')
    if (!state.artist) {
      await dispatch('fetchArtist')
    }
    return state.artist
  },

  async clear({ commit }) {
    commit('clear')
  },
  logout({ commit, dispatch }) {
    // clear prefs in logout() and not in clear() because prefs should only be
    // cleared on explicit logout, not on token expiration or login
    commit('preferences/clear', null, {root: true})
    // cart should be cleared on explicit logout or token expiration
    commit('cart/clear', null, {root: true})

    return Promise.all([
      dispatch('clear'),
      appApiCreds.get('/accounts/signout-no-redirect/'),
    ])
  },
}

// mutations
const mutations = {
  setToken (state, data) {
    state.token = data
  },
  setUser (state, data) {
    state.user = data
  },

  setProfile (state, data) {
    state.profile = data
  },

  setArtistProfile (state, data) {
    state.artistProfile = data
  },
  setArtist (state, data) {
    state.artist = data
  },

  clear(state) {
    Object.keys(state).forEach(key => {
      state[key] = null
    })
  },
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
}
